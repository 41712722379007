<template>
  <div class="textarea_field">
    <label :for="`textareafield_${name}`" v-html="labelProcessed"></label>
    <textarea
        :id="`textareafield_${name}`"
        :placeholder="placeholder"
        :value="value[name]"
        :required="required ? 'required' : ''"
        :style="{'resize': resize ? 'both' : 'none'}"
        rows="4"
        @input="handleInput"
        v-bind="$attrs"></textarea>
  </div>
</template>

<script>
export default {
  name: "TextareaField",
  props: {
    value: Object,
    label: String,
    name: String,
    placeholder: String,
    required: Boolean,
    resize: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    labelProcessed() {
      if (this.required) {
        return `${this.label}<span class="required-star">*</span>`;
      }
      return this.label;
    }
  },
  methods: {
    handleInput(e) {
      this.$emit('input', {...this.value, [this.name]: e.target.value});
    },
  },
}
</script>

<style scoped lang="scss">
  .textarea_field {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: 6px 0;
    label {
      margin-left: 15px;
      font-size: 14px;
    }
    textarea {
      align-self: stretch;
      text-align: center;
      background-color: var(--input-background);
      padding: 10px 12px;
      border-radius: 34px;
      margin: 8px 0;
      outline: none;
      border: none;
    }
  }
</style>
