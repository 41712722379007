<template>
  <div>
    <transition name="slide-fade-in" tag="div">
      <div class="mini-cart" v-show="!showCart" @click="$store.commit('setShowCart', true)">
        <i class="far fa-shopping-basket"></i>
        <div class="items_count" v-show="cart.amount > 0">{{ cart.amount }}</div>
      </div>
    </transition>
    <transition name="slide-fade-in" tag="div">
      <div class="cart-container" v-show="showCart" @click.self="$store.commit('setShowCart', false)">
        <div class="cart">
          <div class="cart_header">
            <div class="cart_header_left">{{ cartAmount }}</div>
            <div class="cart_header_center">Twój koszyk</div>
            <div class="cart_header_right">{{ cartPrice }}</div>
          </div>
          <div class="cart_content">
            <div v-if="cart.partner" class="order-from">
              <span>Zamawiasz z restauracji: <span class="partner-name" @click="goToPartner">{{ cart.partner.name }}</span></span>
            </div>
            <div class="products" v-if="cart.products">
              <InfoBox v-for="product in cart.products" space class="clickable" @click="handleProductClick(product)">
                <div class="p-line">
                  <div class="product_title">
                    {{ product.quantity }} x {{ product.name }}
                  </div>
                  <div v-if="product.product && product.product.price" class="product_price">
                    {{ product.product.price.toFixed(2).replace('.', ',') }} zł
                  </div>
                </div>
                <div class="p-line" v-if="product.variant">
                  <div class="product_variant">{{ product.variant.name }}</div>
                  <div v-if="product.variant.price" class="product_addition_price">{{ product.variant.price.toFixed(2).replace('.', ',') }} zł</div>
                </div>
                <div v-for="addition in product.additions" class="p-line">
                  <div class="product_addition">{{ addition.name }}</div>
                  <div v-if="addition.price" class="product_addition_price">{{ addition.price.toFixed(2).replace('.', ',') }} zł</div>
                </div>
                <div class="hr"></div>
                <div class="p-line">
                  <div>Suma</div>
                  <div v-if="product.price" class="product_addition_price">
                    {{ parseFloat(product.price).toFixed(2).replace('.', ',') }} zł
                  </div>
                </div>
                <div class="p-line" v-if="product.note">
                  <i class="alternative-text">Notatka: {{ product.note }}</i>
                </div>
              </InfoBox>
              <div v-if="cart.products.length" style="padding-bottom: 10px; padding-right: 5px; display: flex; justify-content: flex-end">
                <a style="color: var(--primary);font-size: .8rem" href="#" @click.prevent="clearCart">Wyczyść koszyk</a>
              </div>
            </div>

            <transition name="slide-fade-in" tag="div">
              <InfoBox space v-show="!!cart.discount">
                <div class="p-line">
                  <div>
                    Podsuma
                  </div>
                  <div>
                    {{ cartPriceWithoutDiscount }}
                  </div>
                </div>
              </InfoBox>
            </transition>

            <transition name="slide-fade-in" tag="div">
              <InfoBox space class="clickable" @click="openModal('discountModal')">
                <div class="p-line">
                  <span>Kod rabatowy</span>
                </div>
                <div class="p-line" v-if="!cart.discount">
                  <i class="alternative-text">Kliknij, aby dodać</i>
                </div>
                <div class="p-line" v-else-if="cart.discount.discount_type === 1">
                  <i>{{ cart.discount.code }} (-{{ parseInt(cart.discount.discount) }}%)</i>
                  <span>- {{ (cart.price_without_discount - cart.price).toFixed(2).replace('.', ',') }} zł</span>
                </div>
                <div class="p-line" v-else-if="cart.discount.discount_type === 2">
                  <i>{{ cart.discount.code }}</i>
                  <span>- {{ (cart.price_without_discount - cart.price).toFixed(2).replace('.', ',') }} zł</span>
                </div>
                <div class="p-line" v-else-if="cart.discount.discount_type === 3">
                  <i>{{ cart.discount.code }}</i>
                  <span>Darmowa dostawa</span>
                </div>
              </InfoBox>
            </transition>

            <InfoBox space>
              <div class="p-line">
                <div>
                  Razem zamówienie
                </div>
                <div>
                  {{ cartPrice }}
                </div>
              </div>
              <div class="p-line boxes-price" v-if="!!cart.boxes_price">
                <div>
                  w tym opakowania
                </div>
                <div>
                  {{ parseFloat(cart.boxes_price).toFixed(2).replace('.', ',') }}
                </div>
              </div>
              <transition name="slide-fade-in" tag="div">
                <div class="p-line" v-show="form.delivery_type === 'delivery'">
                  <div>
                    Koszt dostawy
                  </div>
                  <div v-if="cart.delivery_address">
                    <div v-if="cart.discount && cart.discount.discount_type === 3">
                      Darmowa dostawa
                    </div>
                    <div v-else-if="cart.delivery_cost">
                      {{ parseFloat(cart.delivery_cost).toFixed(2).replace('.', ',') }} zł
                    </div>
                    <div v-else>
                      0,00 zł
                    </div>
                  </div>
                  <div v-else>
                    Wybierz adres
                  </div>
                </div>
              </transition>
              <div class="hr"></div>
              <div class="p-line">
                <div class="price_to_pay_title">
                  Do zapłaty
                </div>
                <div class="price_to_pay">
                  {{ priceToPay }}
                </div>
              </div>
            </InfoBox>

            <SwitchSelect
                v-model="form.delivery_type"
                :items="deliveryTypes"
                style="margin-bottom: 15px;"
                @input="saveDeliveryType"
            />

            <transition name="slide-fade-in" tag="div">
              <InfoBox v-show="form.delivery_type === 'delivery'" space class="clickable" @click="openModal('addressModal')">
                <div class="p-line">
                  <span>Adres dostawy</span>
                </div>
                <div v-if="!cart.delivery_address">
                  <div class="p-line">
                    <i class="alternative-text">Kliknij, aby dodać</i>
                  </div>
                </div>
                <div v-else>
                  <div class="p-line" v-if="cart.delivery_address.company">
                    <i class="alternative-text">Firma</i>
                    <span>{{ cart.delivery_address.company }}</span>
                  </div>
                  <div class="p-line" v-if="cart.delivery_address.nip">
                    <i class="alternative-text">NIP</i>
                    <span>{{ cart.delivery_address.nip }}</span>
                  </div>
                  <div class="p-line">
                    <i class="alternative-text">Adres</i>
                    <span>{{ cart.delivery_address.street }} {{ cart.delivery_address.house_nr }}{{ cart.delivery_address.apartment_nr ? `/${cart.delivery_address.apartment_nr}` : '' }}</span>
                  </div>
                  <div class="p-line">
                    <span></span>
                    <span>{{ cart.delivery_address.postcode }} {{ cart.delivery_address.city }}</span>
                  </div>
                  <div class="p-line" v-if="cart.delivery_address.floor_level">
                    <i class="alternative-text">Piętro</i>
                    <span>{{ cart.delivery_address.floor_level }}</span>
                  </div>
                  <div class="p-line" v-if="cart.delivery_address.gate_code">
                    <i class="alternative-text">Kod do bramy</i>
                    <span>{{ cart.delivery_address.gate_code }}</span>
                  </div>
                </div>
              </InfoBox>
            </transition>


            <InfoBox space class="clickable" @click="openModal('notesModal')">
              <div class="p-line">
                <span v-if="form.delivery_type === 'delivery'">Uwagi dotyczące dostawy</span>
                <span v-else-if="form.delivery_type === 'takeaway'">Uwagi dotyczące odbioru</span>
              </div>
              <div class="p-line">
                <i class="alternative-text" v-if="!form.notes">Kliknij, aby dodać</i>
                <i v-else>{{ form.notes }}</i>
              </div>
            </InfoBox>

            <InfoBox space class="clickable" @click="openModal('paymentMethodModal')">
              <div class="p-line">
                <span>Metoda płatności</span>
              </div>
              <div class="p-line">
                <i class="alternative-text" v-if="!form.payment_method_id">Kliknij, aby wybrać</i>
                <div v-else-if="currentPaymentMethod" class="payment_method">
                  <div>{{ currentPaymentMethod.name }}</div>
                  <img v-if="currentPaymentMethod.image" :src="$asset(currentPaymentMethod.image.path)" :alt="currentPaymentMethod.name">
                </div>
              </div>
            </InfoBox>

            <Btn :disabled="!formIsValid" @click="placeOrder" class="order_button">Zamawiam i płacę</Btn>


          </div>

        </div>
      </div>
    </transition>
    <Modal title="Kod rabatowy" ref="discountModal" backdrop-hide>
      <TextField
          v-model="discount"
          name="value"
          placeholder="Wpisz tutaj kod rabatowy..."
          required
          format="uppercase"
          @keypress.enter="checkDiscount"
      />
      <Btn :disabled="discount.value.length === 0" @click="checkDiscount">Zapisz</Btn>
      <div v-show="!!cart.discount" class="remove-discount-code">
        <span @click="deleteDiscount">Usuń kod rabatowy</span>
      </div>
    </Modal>
    <Modal title="Adres dostawy" ref="addressModal" backdrop-hide>
      <TextField
          v-model="address"
          name="street"
          label="Ulica"
          placeholder="Ulica"
          required
          @input="validateAddress"
      />
      <TextField
          v-model="address"
          name="house_nr"
          label="Numer budynku"
          placeholder="Numer budynku"
          required
          :max-length="10"
          @input="validateAddress"
      />
      <TextField
          v-model="address"
          name="apartment_nr"
          label="Numer mieszkania"
          :max-length="6"
          placeholder="Numer mieszkania (opcjonalne)"
      />
      <TextField
          v-model="address"
          v-mask="'##-###'"
          name="postcode"
          label="Kod pocztowy"
          placeholder="Kod pocztowy"
          required
          :max-length="6"
          @input="validateAddress"
      />
      <TextField
          v-model="address"
          name="city"
          label="Miasto"
          placeholder="Miasto"
          required
          @input="validateAddress"
      />

      <TextField
          v-model="address"
          name="floor_level"
          label="Piętro"
          placeholder="Piętro (opjconalne)"
      />
      <TextField
          v-model="address"
          name="gate_code"
          label="Kod do bramy"
          placeholder="Kod do bramy (opcjonalne)"
      />
      <TextField
          v-model="address"
          name="company"
          label="Nazwa firmy"
          placeholder="Nazwa firmy (opcjonalne)"
      />
      <TextField
          v-model="address"
          name="nip"
          label="NIP"
          placeholder="NIP (opcjonalne)"
      />
      <Btn :disabled="!addressIsValid" @click="saveAddress">Zapisz</Btn>
      <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>

    </Modal>
    <Modal :title="form.delivery_type === 'delivery' ? 'Uwagi dotyczące dostawy' : 'Uwagi dotyczące odbioru'" ref="notesModal" backdrop-hide @backdrop-hide="revertNote"> <!-- TODO: odbioru jeżeli wybrany jest odbiór/ dostawy jeżeli wybrana jest dostawa na adres -->
      <TextareaField
          v-model="form"
          name="notes"
          placeholder="Np. Drugie piętro, drzwi na prawo"
          maxlength="160"
      />
      <Btn @click="saveNote">Zapisz</Btn>
    </Modal>
    <Modal title="Metoda płatności" ref="paymentMethodModal" backdrop-hide>
      <div v-if="cart.partner && cart.partner.payment_methods" style="padding: 1px;">
        <InfoBox
            v-for="payment_method in cart.partner.payment_methods"
            class="payment_method"
            :class="{'selected': payment_method.id === form.payment_method_id}"
            @click="handleChangePaymentMethod(payment_method)">
          <img v-if="payment_method.image" :src="$asset(payment_method.image.path)" :alt="payment_method.name">
          <div>{{ payment_method.name }}</div>
        </InfoBox>
      </div>
    </Modal>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import InfoBox from "@/parts/InfoBox";
import Btn from "@/components/Btn";
import Modal from "@/components/Modal";
import TextareaField from "@/components/TextareaField";
import SwitchSelect from "@/components/SwitchSelect";
import TextField from "@/components/TextField";
import { VueMaskDirective } from 'v-mask';

export default {
  name: "Cart",
  components: {TextField, SwitchSelect, TextareaField, Modal, Btn, InfoBox},
  directives: {
    mask: VueMaskDirective,
  },
  data() {
    return {
      form: {
        notes: '',
        delivery_type: 'delivery',
        social_distance: false,
        payment_method_id: '',
      },
      address: {
        street: '',
        house_nr: '',
        apartment_nr: '',
        city: '',
        postcode: '',
        country: 'Poland',

        company: '',
        floor_level: '',
        gate_code: '',
        nip: '',
      },
      discount: {
        value: '',
      },
      formIsValid: false,
      addressIsValid: false,
      deliveryTypes: [
        {label: 'Dostawa', value: 'delivery'},
        {label: 'Odbiór osobisty', value: 'takeaway'},
      ],
    };
  },
  computed: {
    ...mapGetters([
      'showCart',
      'cart',
    ]),
    cartPrice() {
      if (!this.cart.price) {
        return '';
      }
      return `${parseFloat(this.cart.price).toFixed(2).replace('.', ',')} zł`
    },
    cartPriceWithoutDiscount() {
      if (!this.cart.price_without_discount) {
        return '';
      }
      return `${parseFloat(this.cart.price_without_discount).toFixed(2).replace('.', ',')} zł`
    },
    cartAmount() {
      if (!this.cart.amount) {
        return 'Dodaj produkty';
      }
      if (this.cart.amount === 1) {
        return '1 pozycja';
      } else if (this.cart.amount > 1 && this.cart.amount < 5) {
        return `${this.cart.amount} pozycje`;
      } else {
        return `${this.cart.amount} pozycji`;
      }
    },
    priceToPay() {
      let price = 0;
      if (!this.cart.delivery_cost || this.form.delivery_type === 'takeaway') {
        price = parseFloat(this.cart.price);
      } else {
        price = (parseFloat(this.cart.price) + parseFloat(this.cart.delivery_cost));
      }
      if (isNaN(price)) {
        return '0,00 zł';
      }
      return `${price.toFixed(2).replace('.',',')} zł`;
    },
    currentPaymentMethod() {
      if (!this.form.payment_method_id || !this.cart.partner || !this.cart.partner.payment_methods) {
        return null;
      }
      return this.cart.partner.payment_methods.find(a => a.id === this.form.payment_method_id);
    },
  },
  watch: {
    showCart: {
      handler(val) {
        if (val) {
          this.$store.dispatch('reCalcCart');
        }
      },
    },
    cart: {
      handler(val) {
        if (val.id) {
          if (val.delivery_address) {
            this.$set(this, 'address', {
              street: val.delivery_address.street,
              house_nr: val.delivery_address.house_nr,
              apartment_nr: val.delivery_address.apartment_nr,
              city: val.delivery_address.city,
              postcode: val.delivery_address.postcode,
              country: val.delivery_address.country,

              company: val.delivery_address.company,
              floor_level: val.delivery_address.floor_level,
              gate_code: val.delivery_address.gate_code,
              nip: val.delivery_address.nip,
            });
          }
          this.$set(this.form, 'notes', val.notes);
          if (val.delivery_type) {
            this.$set(this.form, 'delivery_type', val.delivery_type);
          } else {
            this.saveDeliveryType();
          }
          this.$set(this.form, 'social_distance', val.social_distance === 1);
          this.$nextTick(() => {
            this.validateAddress();
            this.validateForm();
          });
        }
      },
      deep: true,
      immediate: true,
    }
  },
  methods: {
    openModal(modal) {
      if (this.$refs[modal]) {
        this.$refs[modal].show();
      }
    },
    clearCart() {
      this.$store.dispatch('cartClear').then(() => {

      });
    },
    goToPartner() {
      if (this.cart.partner) {
        this.$router.push({ name: 'Restaurant', params: { id: this.cart.partner.id } });
        this.$store.commit('setShowCart', false)
      }
    },
    checkDiscount() {
      this.$store.dispatch('cartAddDiscount', {form: { discount_code: this.discount.value.toUpperCase() } }).then(({ statusCode, message }) => {
        if (statusCode === 200) {
          this.$toast.success('Dodano kod rabatowy');
          this.$refs.discountModal.hide();
        } else if (statusCode !== 500) {
          if (message === 'empty_code') {
            // setShowDiscountCodeModal(false);
            // setDiscountCodeError(null);
          } else if (message === 'cart_empty') {
            this.$toast.error('Twój koszyk jest pusty');
          } else if (message === 'invalid_code') {
            this.$toast.error('Nieprawidłowy kod');
          } else if (message.includes('min_price_not_reached')) {
            this.$toast.error('Nie osiągnięto minimalnej ceny do wykorzystania tego kodu rabatowego');
          } else if (message === 'user_used_this_code') {
            this.$toast.error('Już użyłeś ten kod rabatowy');
          } else if (message === 'limit_of_use_achieved') {
            this.$toast.error('Limit użyć tego kodu rabatowego został już wykorzystany');
          } else if (message === 'code_to_different_partner') {
            this.$toast.error('Ten kod jest nieprawidłowy dla tej restauracji');
          } else if (message === 'code_expired') {
            this.$toast.error('Ten kod rabatowy wygasł');
          } else if (message === 'empty_delivery_cost') {
            this.$toast.error('Wybierz najpierw adres dostawy');
          } else if (message === 'delivery_cost_too_high') {
            this.$toast.error('Ten kod jest nieprawidłowy dla tej restauracji');
          } else if (message === 'error_code_free_delivery_to_takeaway') {
            this.$toast.error('Nie możesz użyć darmowej dostawy do odbioru osobistego');
          } else {
            console.warn(message);
          }
        }
      });
    },
    handleChangePaymentMethod(paymentMethod) {
      this.form.payment_method_id = paymentMethod.id;
      this.$refs.paymentMethodModal.hide();
      this.$nextTick(() => {
        this.validateForm();
      });
    },
    validateAddress() {
      let ret = true;
      if (!this.address.street) {
        ret = false;
      }
      if (!this.address.house_nr) {
        ret = false;
      }
      if (!this.address.city) {
        ret = false;
      }
      if (!this.address.postcode) {
        ret = false;
      }
      this.addressIsValid = ret;
      return ret;
    },
    saveAddress() {
      if (!this.validateAddress()) {
        return;
      }
      this.$store.dispatch('setCartAddress', { form: this.address }).then(({statusCode, data}) => {
        if (statusCode === 200) {
          this.$refs.addressModal.hide();
        } else if (statusCode === 400) {
          data.forEach((er) => {
            const key = Object.keys(er)[0];
            if (key === 'street') {
              this.$toast.warning(`Błędna ulica, może chodziło Ci o ${er.street}?`, {
                duration: 12000
              });
            } else if (key === 'house_nr') {
              this.$toast.warning(`Błędny numer domu, może chodziło Ci o ${er.house_nr}?`, {
                duration: 12000
              });
            } else if (key === 'city') {
              this.$toast.warning(`Błędne miasto, może chodziło Ci o ${er.city}?`, {
                duration: 12000
              });
            } else if (key === 'postcode') {
              this.$toast.warning(`Błędny kod pocztowy, może chodziło Ci o ${er.postcode}?`, {
                duration: 12000
              });
            }
          });
        }
      });
    },
    revertNote() {
      this.$set(this.form, 'notes', this.cart.notes);
    },
    saveNote() {
      this.$store.dispatch('setCartNotes', {form: {notes: this.form.notes}}).then(() => {
        this.$refs.notesModal.hide();
      });
    },
    saveDeliveryType() {
      this.$store.dispatch('setCartDeliveryType', {form: {delivery_type: this.form.delivery_type}});
    },
    validateForm() {
      let ret = true;
      if (!this.cart.products || this.cart.products.length === 0) {
        ret = false;
      }
      if (!this.form.payment_method_id) {
        ret = false;
      }
      if (this.cart.delivery_type === 'delivery' && !this.cart.delivery_address) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    deleteDiscount() {
      this.$store.dispatch('cartDeleteDiscount').then(() => {
        this.$refs.discountModal.hide();
      });
    },
    handleProductClick(product) {
      if (!this.cart.partner) {
        return;
      }
      this.$router.push({ name: 'Additions', params: {
          restaurant_id: this.cart.partner.id,
          id: product.product_id,
          cart_product_id: product.id,
        }
      });
      this.$store.commit('setShowCart', false);
    },
    placeOrder() {
      if (!this.validateForm()) {
        return;
      }
      this.$store.dispatch('placeOrder', {
        form: {
          web: true,
          social_distance: this.form.social_distance ? 1 : 0,
          payment_method_id: this.form.payment_method_id,
        },
      }).then(({ statusCode, data, message }) => {
        if (statusCode === 200) {
          if (data && data.redirectUri) {
            window.location.assign(data.redirectUri);
          }
        } else if (statusCode !== 500) {
          if (message === 'user_not_logged') {
            this.$toast.error('Musisz być zalogowany, aby złożyć zamówienie');
          } else if (message === 'cart_not_found') {
            this.$toast.error('Nie znaleziono koszyka');
          } else if (message === 'empty_cart' || message === 'cart_empty') {
            this.$toast.error('Twój koszyk jest pusty, dodaj coś do niego');
          } else if (message === 'empty_email') {
            this.$toast.error('Podaj adres e-mail');
          } else if (message === 'empty_delivery_address') {
            this.$toast.error('Podaj adres dostawy');
          } else if (message === 'empty_payment_method') {
            this.$toast.error('Wybierz metodę płatności');
          } else if (message === 'incorrect_payment_method_id') {
            this.$toast.error('Niepoprawna metoda płatności');
          } else if (message === 'empty_cart_products_partner') {
            this.$toast.error('Wystąpił błąd, spróbuj ponownie za chwilę');
          } else if (message === 'cart_price_too_low') {
            this.$toast.error('Wartość koszyka jest za mała');
          } else if (message === 'incorrect_payment_method_to_discount') {
            this.$toast.error('Nie możesz użyć tej metody płatności z kodem rabatowym');
          } else if (message === 'invalid_code') {
            this.$toast.error('Nieprawidłowy kod rabatowy');
          } else if (message === 'error_code_free_delivery_to_takeaway') {
            this.$toast.error('Nie możesz użyć darmowej dostawy do odbioru osobistego');
          } else if (message === 'code_expired') {
            this.$toast.error('Ten kod rabatowy wygasł');
          } else if (message === 'user_used_this_code') {
            this.$toast.error('Już użyłeś ten kod rabatowy');
          } else if (message === 'limit_of_use_achieved') {
            this.$toast.error('Limit użyć tego kodu rabatowego został już wykorzystany');
          } else if (message === 'code_to_different_partner') {
            this.$toast.error('Ten kod jest nieprawidłowy dla tej restauracji');
          } else if (message === 'empty_delivery_cost') {
            this.$toast.error('Wybierz najpierw adres dostawy');
          } else if (message === 'delivery_cost_too_high') {
            this.$toast.error('Ten kod jest nieprawidłowy dla tej restauracji');
          } else if (message === 'partner_inactive') {
            this.$toast.error('Restauracja jest w tej chwili niedostępna'); // TODO:
            // setShowClearCart(true);
          } else if (message === 'partner_closed') {
            this.$toast.error('Restauracja jest w tej chwili niedostępna'); // TODO:
            // setShowClearCart(true);
          } else if (message === 'this_code_is_only_to_takeaway') {
            this.$toast.error('Ten kod rabatowy może być użyty tylko do zamówień z odbiorem osobistym');
          } else if (message.includes('min_price_not_reached')) {
            this.$toast.error('Nie osiągnięto minimalnej ceny do wykorzystania tego kodu rabatowego');
          } else {
            this.$toast.error(`Can't place order ${statusCode} - ${message}`);
            // console.warn(`Can't place order ${statusCode} - ${message}`);
            // TODO: handle other errors
          }
        } else {
          this.$toast.error(`Can't place order ${statusCode} - ${message}`);
          // console.warn(`Can't place order ${statusCode} - ${message}`);
          // TODO: report error
        }
      });
    },
  },
  created() {
    this.$store.dispatch('fetchCart');
  },

}
</script>

<style scoped lang="scss">
.mini-cart {
  z-index: 3;
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background-color: var(--primary);
  color: var(--white);
  border-radius: 50%;
  border-color: var(--border);
  border-width: 1px;
  border-style: solid;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: box-shadow .2s ease;
  font-size: 20px;
  .items_count {
    position: absolute;
    top: -4px;
    right: -4px;
    background-color: var(--secondary);
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    border-color: var(--border);
    border-width: 1px;
    border-style: solid;
    font-size: 14px;
  }
  &:hover, &:focus {
    box-shadow: var(--shadow-one);
  }
}
.cart-container {
  z-index: 9;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .2);
  display: flex;
  justify-content: center;
  align-items: center;
  .cart {
    max-width: 600px;
    width: 100%;
    height: calc(100% - 80px);
    margin-top: 70px;
    margin-bottom: 10px;
    background-color: var(--default-background);
    overflow: hidden;
    border-radius: var(--border-radius);
    .cart_header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      background-color: var(--primary);
      color: var(--white);
      .cart_header_left {
        flex: 1;
        text-align: left;
      }
      .cart_header_center {
        flex: 1;
        font-size: 1.1rem;
      }
      .cart_header_right {
        flex: 1;
        text-align: right;
      }
    }
    .cart_content {
      padding: 15px;
      overflow-y: auto;
      height: calc(100% - 76px);
    }
    .p-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      &.boxes-price {
        margin-left: 10px;
        font-size: .9rem;
        color: var(--text-secondary);
      }
    }
    .product_title {
      font-weight: 500;
    }
    .product_price {
      font-weight: 500;
    }
    .product_variant {
      font-style: italic;
    }
    .product_addition {
      padding-left: 10px;
    }
    .product_addition_price {

    }
    .hr {
      width: calc(100% - 30px);
      padding-left: 15px;
      padding-right: 15px;
      height: 1px;
      background-color: var(--border);
      border-radius: 3px;
      margin-top: 5px;
      margin-bottom: 5px;
    }
    .price_to_pay {
      font-weight: bold;
      color: var(--primary);
    }
    .price_to_pay_title {
      font-weight: bold;
      font-size: 1.1rem;
    }
    .order_button {
      width: calc(100% - 20px);
      position: sticky;
      bottom: 0;
    }
    .alternative-text {
      color: var(--text-secondary);
    }
    .clickable {
      cursor: pointer;
      &:hover, &:focus {
        background-color: var(--input-background-secondary);
      }
    }
    .order-from {
      text-align: left;
      margin-bottom: 10px;
      .partner-name {
        font-weight: 500;
        cursor: pointer;
        color: var(--primary);
      }
    }
  }
}
.payment_method {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
  cursor: pointer;
  flex: 1;
  img {
    width: 50px;
    height: 30px;
    object-fit: contain;
  }
  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }
  &.selected {
    background-color: var(--primary);
    color: var(--white);
    &:hover, &:focus {
      background-color: var(--secondary);
    }
  }
}
.remove-discount-code {
  margin-top: 6px;
  color: var(--primary);
  span {
    cursor: pointer;
  }
}
</style>
