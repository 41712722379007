<template>
  <transition name="slide-fade-in" tag="div">
    <div class="modal_outer" v-show="isShowed" @click.self="handleBackdrop">
      <div class="modal_inner">
        <div class="modal_title">{{ title }}</div>
        <div class="modal_content">
          <slot></slot>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Modal",
  props: {
    title: String,
    backdropHide: Boolean,
  },
  data() {
    return {
      isShowed: false,
    };
  },
  methods: {
    show() {
      this.isShowed = true;
    },
    hide() {
      this.isShowed = false;
    },
    toggle() {
      this.isShowed = !this.isShowed;
    },
    handleBackdrop() {
      if (this.backdropHide) {
        this.hide();
        this.$emit('backdrop-hide');
      }
    },

  }
}
</script>

<style scoped lang="scss">
.modal_outer {
  z-index: 15;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .3);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .modal_inner {
    border-radius: var(--border-radius);
    background-color: var(--default-background);
    max-width: 500px;
    width: 100%;
    height: fit-content;
    padding: 20px;
    .modal_title {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      font-weight: 500;
      font-size: 1.1rem;
      position: sticky;
    }
    .modal_content {
      max-height: calc(100vh - 76px);
      overflow-y: auto;
    }
  }
}
</style>
