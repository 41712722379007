<template>
  <div class="switch_select">
    <div class="cursor" :style="cursorStyles"></div>
    <div v-for="item in items" class="element" :class="{ 'selected': item.value === value }" @click="selectItem(item)">
      {{ item.label }}
    </div>
  </div>
</template>

<script>
export default {
  name: "SwitchSelect",
  props: {
    items: Array,
    value: String,
  },
  computed: {
    selectedIndex() {
      if (!this.value) {
        return 0;
      }
      return this.items.findIndex(a => a.value === this.value);
    },
    cursorStyles() {
      return {
        'width': `${100 / this.items.length}%`,
        'left': `${(100 / this.items.length) * this.selectedIndex}%`,
      };
    }
  },
  methods: {
    selectItem(item) {
      this.$emit('input', item.value);
    },
  },

}
</script>

<style scoped lang="scss">
.switch_select {
  position: relative;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  border-width: 1px;
  border-style: solid;
  border-color: var(--border);
  overflow: hidden;
  .element {
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex: 1;
    padding-top: 10px;
    padding-bottom: 10px;
    border-right-color: var(--border);
    border-right-style: solid;
    border-right-width: 1px;
    cursor: pointer;
    transition: background-color .2s ease, color .3s ease;
    &:hover, &:focus {
      //background-color: var(--input-background-secondary);
      background-color: rgba(0, 0, 0, .05);
    }
    &:last-of-type {
      border-right: none;
    }
    &.selected {
      color: var(--white);
    }
  }
  .cursor {
    position: absolute;
    background-color: var(--primary);
    height: 100%;
    transition: left .3s ease;
  }
}
</style>
