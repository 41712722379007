<template>
  <div class="restaurant-info">
    <div class="container">
      <div class="elem" title="Godziny otwarcia">
        <div class="icon">
          <img src="@/assets/images/icons/open-new.svg" alt="Open">
        </div>
        <div class="content">
          {{ restaurant.today_open }}
        </div>
      </div>
      <div class="elem" title="Czas dostawy">
        <div class="icon">
          <img src="@/assets/images/icons/time-new.svg" alt="Time">
        </div>
        <div class="content">
          {{ restaurant.min_time_delivery }}-{{ restaurant.max_time_delivery }} min
        </div>
      </div>
      <div class="elem" title="Ocena restauracji" v-if="restaurant.rate > 0">
        <div class="icon">
          <img src="@/assets/images/icons/like-new.svg" alt="Like">
        </div>
        <div class="content">
<!--          {{ restaurant.number_opinions }}-->
          {{ (Math.round(restaurant.rate * 10) / 10).toFixed(1) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RestaurantInfo",
  props: {
    restaurant: Object,
  },
}
</script>

<style scoped lang="scss">
.restaurant-info {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  .container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 10px;
    background-color: var(--white);
    border-radius: 34px;
    //height: 36px;
    height: 44px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--border);
    padding: 0 10px;
  }

  .elem {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 0 8px;
    .icon {
      img {

      }
    }
    .content {
      margin-left: 6px;
    }
  }
}
</style>
