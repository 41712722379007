<template>
  <span class="tag" :style="{ backgroundColor: color }"><slot></slot></span>
</template>

<script>
export default {
  name: "Tag",
  props: {
    color: String,
  },
}
</script>

<style lang="scss" scoped>
  .tag {
    border-radius: 8px;
    color: #ffffff;
    padding: 2px 10px;
    font-size: .9rem;
    margin: 0 2px;
  }
</style>
