<template>
  <div class="menu_element_container">
    <div class="menu_header">
      {{ label }}
    </div>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "MenuHeader",
  props: {
    label: {
      type: String,
    },
  },
}
</script>

<style scoped lang="scss">
.menu_element_container {
  .menu_header {
    position: sticky;
    z-index: 1;
    top: 60px;
    background-color: var(--default-background);
    padding-bottom: 10px;
    padding-top: 10px;
    font-weight: 500;
  }
}
</style>
