<template>
  <transition name="slide-fade-in">
    <div class="search-address-outer" v-show="showSearchAddress" @click.self="$store.commit('setShowSearchAddress', false)">
      <div class="search-address">
        <TextField
            v-model="form"
            name="street"
            label="Ulica"
            placeholder="Ulica"
            required
            @input="validateFormTick"
        />
        <TextField
            v-model="form"
            name="house_nr"
            label="Numer budynku"
            placeholder="Numer budynku"
            required
            :max-length="10"
            @input="validateFormTick"
        />
        <TextField
            v-model="form"
            name="apartment_nr"
            label="Numer mieszkania"
            :max-length="6"
            placeholder="Numer mieszkania (opcjonalne)"
        />
        <TextField
            v-model="form"
            v-mask="'##-###'"
            name="postcode"
            label="Kod pocztowy"
            placeholder="Kod pocztowy"
            required
            @input="validateFormTick"
        />
        <TextField
            v-model="form"
            name="city"
            label="Miasto"
            placeholder="Miasto"
            required
            @input="validateFormTick"
        />
        <Btn :disabled="!formIsValid" @click="saveAddress">Zapisz</Btn>
        <p style="text-align: left; font-size: 13px;"><span class="required-star">*</span> Pole wymagane</p>
      </div>
    </div>
  </transition>
</template>

<script>
import {mapGetters} from 'vuex';
import TextField from "@/components/TextField";
import Btn from "@/components/Btn";
import { VueMaskDirective } from 'v-mask';

export default {
  name: "SearchAddress",
  directives: {
    mask: VueMaskDirective
  },
  components: {
    Btn,
    TextField,
  },
  data() {
    return {
      form: {
        street: '',
        house_nr: '',
        apartment_nr: '',
        postcode: '',
        city: '',
      },
      formIsValid: false,
    };
  },
  computed: {
    ...mapGetters([
      'showSearchAddress',
      'address',
    ]),
  },
  watch: {
    address: {
      handler(val) {
        this.$set(this, 'form', {
          street: val.street,
          house_nr: val.house_nr,
          apartment_nr: val.apartment_nr,
          postcode: val.postcode,
          city: val.city,
        });
        this.validateFormTick();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    validateFormTick() {
      this.$nextTick(() => {
        this.validateForm();
      });
    },
    validateForm() {
      let ret = true;
      if (!this.form.street) {
        ret = false;
      }
      if (!this.form.house_nr) {
        ret = false;
      }
      if (!this.form.city) {
        ret = false;
      }
      if (!this.form.postcode) {
        ret = false;
      }
      this.formIsValid = ret;
      return ret;
    },
    saveAddress() {
      if (!this.validateForm()) {
        return;
      }
      this.$store.dispatch('getAddressByAddress', { form: { address: this.form } }).then(({ statusCode, message = '' }) => {
        if (statusCode === 400) {
          if (message === 'not_found') {
            this.$toast.error('Nie znaleźliśmy podanego adresu.<br>Popraw adres i spróbuj ponownie');
          } else if (message === 'not_found_city') {
            this.$toast.error('Nie znaleźliśmy miasta.<br>Popraw adres i spróbuj ponownie');
          } else if (message === 'not_found_house_nr') {
            this.$toast.error('Nie znaleźliśmy numeru ulicy.<br>Popraw adres i spróbuj ponownie');
          } else if (message === 'not_found_city_id') {
            this.$toast.error('Niestety nie jesteśmy jeszcze dostępni w wybranym mieście');
          } else {
            this.$toast.error(message);
          }
        }
      });
      this.$store.commit('setShowSearchAddress', false);
    }
  }
}
</script>

<style scoped lang="scss">
  .search-address-outer {
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    height: calc(100% - 60px);
    width: 100%;
    background-color: rgba(0, 0, 0, .2);
    display: flex;
    justify-content: center;
    z-index: 10;
    .search-address {
      margin-top: 10px;
      height: fit-content;
      width: 100%;
      max-width: 500px;
      padding: 15px;
      border-radius: var(--border-radius-bottom);
      background-color: var(--default-background);
      box-shadow: var(--shadow-one);
    }
  }
</style>
