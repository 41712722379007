<template>
  <div class="menu_item" @click="navigateToAdditions">
    <div class="menu_item_content">
      <div class="menu_item_line">
        <h4 style="margin-bottom: 2px">{{ item.name }}</h4>
        <div style="display: flex; flex-direction: row; margin-top: -4px">
          <Tag v-for="tag in item.tags" :color="tag.color">{{ tag.name }}</Tag>
        </div>
      </div>
      <p v-if="item.description" class="menu_item_description">{{ item.description }}</p>
      <div class="menu_item_price_container">
        <h5 class="menu_item_price">
          {{ itemPrice }}&nbsp;zł
          <span class="box_price" v-if="boxPrice">+ opakowanie {{ boxPrice }}&nbsp;zł</span>
        </h5>
      </div>
    </div>
    <img
        v-if="item.image"
        :src="$asset(item.image.path)"
        alt="item.image.original_name"
        class="menu_item_image"
    />
  </div>
</template>

<script>
import Tag from "@/components/Tag";

export default {
  name: "MenuItem",
  components: {Tag},
  props: {
    item: {
      type: Object,
    },
    restaurantId: {
      type: String,
    },
  },
  computed: {
    itemPrice() {
      if (!this.item.box_price || this.item.box_price === 0) {
        return this.item.price.toFixed(2).replace('.', ',');
      }
      return (this.item.price - this.item.box_price).toFixed(2).replace('.', ',');
    },
    boxPrice() {
      if (!this.item.box_price || this.item.box_price === 0) {
        return false;
      }
      return this.item.box_price.toFixed(2).replace('.', ',');
    }
  },
  methods: {
    navigateToAdditions() {
      this.$router.push({ name: 'Additions', params: {restaurant_id: this.restaurantId, id: this.item.id} });
    },
  },
}
</script>

<style scoped lang="scss">
.menu_item {
  margin: 1px 15px 10px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: var(--white);
  min-height: 96px;
  overflow: hidden;
  border-width: 1px;
  border-color: var(--border);
  border-style: solid;
  cursor: pointer;
  transition: background-color .2s ease;

  p, h4, h5 {
    margin: 0;
  }

  .menu_item_content {
    display: flex;
    flex-direction: column;
    padding: 7px 10px;
    flex: 1;
    justify-content: space-between;
  }
  .menu_item_line {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .menu_item_description {
    display: flex;
    flex: 1;
    font-size: 12px;
    margin-bottom: 2px;
    color: var(--text-secondary);
    text-align: left;
  }
  .menu_item_price_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
  .menu_item_price {
    display: flex;
    margin-top: 3px;
    color: var(--primary);
  }
  .menu_item_image {
    width: 96px;
    height: 100%;
    object-fit: cover;
  }
  .box_price {
    display: inline-block;
    margin-left: 6px;
    font-weight: normal;
    color: var(--text-secondary);
  }


  &:hover, &:focus {
    background-color: var(--input-background-secondary);
  }

}
</style>
